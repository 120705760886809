import React from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import SectionsAscent from "./SectionsAscent"

import { anm } from "../transitions/transitions"
import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import dimensions from "dataset/dimensions"
import FloorPlate from "components/FloorPlate/FloorPlate"

function FloorplanAscent({ section, action, isFav, apartment }) {
  const transition = useTransition(section === "FLOORPLAN", anm)

  const floor = isFav ? apartment[APART_PROPS.floor] : apartment[APART_PROPS.floor]

  let route = ""
  route = apartment?.[APART_PROPS.unitType]?.split(" ")[1]?.toLowerCase()

  const aptDimensions = route && Object.keys(dimensions?.[route])

  const egertonSmallBeds = [
    "1008",
    "1308",
    "1608",
    "1908",
    "908",
    "1108",
    "1208",
    "1408",
    "1508",
    "1708",
    "1808",
  ]
  const lineHeight = window.innerHeight ?? 600
  const lineWidth = lineHeight / 3
  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles} $unit={apartment[APART_PROPS.unit]}>
          <Lines viewBox={`0 0 ${lineWidth} ${lineHeight}`} style={{ zIndex: 2 }}>
            <polyline
              stroke="#b58762"
              strokeOpacity={0.3}
              strokeWidth={2}
              points={`${lineWidth * 0.5},0 ${lineWidth * 0.5},${lineHeight * 0.583}`}
              vectorEffect="non-scaling-stroke"
            />
            <polyline
              stroke="#b58762"
              strokeOpacity={0.3}
              strokeWidth={2}
              points={`0,${lineHeight} ${lineWidth * 0.5},${
                lineHeight * 0.583
              } ${lineWidth},${lineHeight}`}
              fill="none"
              vectorEffect="non-scaling-stroke"
            />
          </Lines>
          <SideBar>
            <Header>
              <h3>ASCENTA</h3>
              <h1>
                N° <span>{apartment[APART_PROPS.unit]}</span>
              </h1>
              <h4>{BEDLABELS[apartment[APART_PROPS.beds]]}</h4>
              <ul>
                {aptDimensions?.map((key, i) => {
                  return (
                    <li key={`li${i}`}>
                      <p>
                        <span>{key}</span>
                        {route &&
                        key === "BEDROOM 1" &&
                        egertonSmallBeds.includes(apartment[APART_PROPS.unit])
                          ? "3.41m x 3.37m | 11’2” x 11’1”"
                          : dimensions[route]?.[key]}
                      </p>
                    </li>
                  )
                })}
              </ul>
            </Header>
            <FloorPlateWrapper
              style={isFav ? { pointerEvents: "none" } : {}}
              className="ascent-plate">
              <FloorPlate apartment={apartment} floor={floor} isFav={isFav} />
            </FloorPlateWrapper>
            <SectionsWrapper>
              <SectionsAscent action={action} section={section} apartment={apartment} />
            </SectionsWrapper>
          </SideBar>
          <FloorplanViewer unit={apartment[APART_PROPS.unit]} />
        </Wrapper>
      ),
  )
}

function FloorplanViewer({ unit }) {
  return (
    <PlanWrapper>
      <Plan src={`assets/images/finder/plans/${unit}.png`}/>
      <Conventions>
        <div className="item">
          <span>B</span> - Boiler
        </div>{" "}
        <span>| </span>
        <div className="item">
          <span>UC</span> - Utility Cupboard
        </div>
        <span>| </span>
        <div className="item">
          <span>W</span> - Wardrobe
        </div>
        <span>| </span>
        <div className="item">
          <span>W/D</span> - Washer & Dryer
        </div>
        <span>| </span>
        <div className="item">
          <span>HIU</span> - Heat Interface Unit
        </div>
      </Conventions>
      <p className="disclaimer">
        Total floor area may vary slightly to the figure that is listed. The dimensions shown are
        accurate to within +/- 50mm, they are not in- tended to be used for carpet sizes,
        appliances, spaces or items of furniture. Wardrobes are included in the master bedroom only,
        all oth- er furniture is indicative and not supplied. Map is not to scale. Subject to
        change. September 2022. *This home is wheelchair accessible.
      </p>
    </PlanWrapper>
  )
}

export default FloorplanAscent

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
`

const PlanWrapper = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .disclaimer {
    font-size: 0.5em;
    width: 60%;
    text-align: center;
    font-family: Bliss-Light;
    font-weight: 100;
    opacity: 1;
    margin-top: auto;
    margin-bottom: 2%;
  }
`

const Plan = styled(a.img)`
  position: relative;
  height: 85%;
  width: 100%;
  object-fit: contain;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`
const Conventions = styled.div`
  position: relative;
  font-size: 1vw;
  font-family: Bliss-Light;
  text-align: center;
  width: 40%;
  max-width: 40em;
  white-space: pre-line;
  line-height: 1.5em;

  & span {
    color: #b58762;
    filter: none !important;
  }

  .item {
    display: inline-block;
    margin-right: 10px;
  }

  .icon {
    margin-right: 5px;
    vertical-align: middle;
  }

  .icon img {
    height: 15px;
  }
`

const SideBar = styled.div`
  position: relative;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  touch-action: auto;
  color: black;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 2% 0%;
  padding-top: 0;

  & h4 {
    font-size: 0.7em;
    letter-spacing: 0.2em;
    text-align: center;
    font-weight: 100;
    color: black;
    font-family: Bliss-Light;
    margin-bottom: 8%;
  }
  & h3 {
    font-size: 0.8em;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: 600;
    color: #b58762;
  }
  & h1 {
    font-size: 1.8em;
    letter-spacing: 0.1em;
    line-height: 0;
    text-align: center;
    font-weight: 600;
    color: #b58762;
    & span {
      font-family: Classico;
    }
  }

  & ul {
    font-size: 0.85em;
    list-style: none;
    padding-left: 0;
    margin-bottom: 8%;
  }

  & li {
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 2% 0%;
    gap: 3%;
    text-align: center;

    & h4 {
      width: 10%;
      aspect-ratio: 1/1;
      border: 1px solid #b58762;
      font-size: 0.8em;
      color: #b58762;
      display: grid;
      place-content: center;
      border-radius: 100%;
    }

    & p {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-weight: 100;
      justify-content: center;
      font-family: Bliss-Light;
      font-size: 0.8em;

      & span {
        color: #b58762;
        font-weight: 100;
        font-family: Bliss-Light;
        font-size: 1em;
      }
    }
  }
`

export const FloorPlateWrapper = styled(a.div)`
  padding: 1%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    opacity: 1;
    filter: none;
  }
`

const SectionsWrapper = styled.div`
  width: 60%;
  z-index: 3;
`

export const Lines = styled.svg`
  position: absolute;
  z-index: 0;
  left: 15%;
  width: 30%;
  height: 100%;
  pointer-events: none;
  /* max-width: 600px; */
`

export const BEDLABELS = {
  1: "ONE BEDROOM APARTMENT",
  2: "TWO BEDROOM APARTMENT",
  3: "THREE BEDROOM APARTMENT",
}
