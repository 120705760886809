/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react"
import { a, useSpring, useTransition } from "react-spring"
import styled from "styled-components"

import Sections from "./Sections"

import {anm} from "../transitions/transitions"
import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import Left from "assets/images/icons/left.svg"
import Right from "assets/images/icons/right.svg"
import Views360 from "./Views360"

import { range } from "lodash-es"

function Views({ section, action, isFav, apartment }) {
  const [routeIndex, setRouteIndex] = useState(0)
  const [disabled, setDisabled] = useState(false)
  let activeApartment = useStore(s => s.activeApartment)
  const transition = useTransition(section === "VIEWS", anm)
  if (isFav) {
    activeApartment = apartment
  }

  let aspects = activeApartment?.[APART_PROPS.aspect].split("-").sort((a, b) => {
    switch (a) {
      case "N":
        a = 0
        break
      case "E":
        a = 1
        break
      case "S":
        a = 2
        break
      case "W":
        a = 3
        break
      default:
        break
    }
    switch (b) {
      case "N":
        b = 0
        break
      case "E":
        b = 1
        break
      case "S":
        b = 2
        break
      case "W":
        b = 3
        break
      default:
        break
    }

    return a === b ? 0 : a > b ? 1 : -1
  })

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false)
    }, 600)
  }, [disabled])

  const fullScreenViews = useStore(s => s.fullScreenViews)

  const { opacity2 } = useSpring({
    opacity2: fullScreenViews ? 0 : 1,
  })

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          <Views360 activeApartment={activeApartment} section={section} />
          <SideBar style={{ opacity: opacity2 }}>
            <Line />
            <SectionsWrapper>
              <Sections action={action} section={section} />
            </SectionsWrapper>
          </SideBar>
        </Wrapper>
      ),
  )
}

export default Views

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`

const SideBar = styled(a.div)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 28%;
  height: 25%;
  padding: 2.5%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
`

const SectionsWrapper = styled.div`
  flex: 1 1;
`

const Line = styled.hr`
  width: 80%;
  height: 1px;
  margin-left: 10%;
  background-color: white;
  border: none;
`

const Background = styled(a.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: ${p => `url(assets/images/finder/views/${p.level}/${p.route}.jpg)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  width: 30%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .disabled {
    pointer-events: none;
    opacity: 0;
  }
`

const ViewIndicator = styled.p`
  padding: 4% 4% 2% 4%;
  background-color: #b58762;
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  line-height: unset;
  opacity: 0.8;
`

const Arrow = styled.img`
  margin: 0% 5%;
  max-height: 5rem;
  opacity: 0.8;
`
