import Fav from "assets/images/icons/fav.svg"
import FavActive from "assets/images/icons/fav-active.svg"
import styled from "styled-components"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"

import { useStore } from "state/store"

export default function FavouriteButton({apartment = null}) {
  const activeApartment = useStore(s => s.activeApartment)
  const favorites = useStore(s => s.favorites)
  const toggleFavorites = useStore(s => s.toggleFavourites)
  const isFav = favorites.includes(apartment? apartment : activeApartment)

  const transitions = useTransition(isFav, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <FavButton
      onClick={() => {
        toggleFavorites(apartment? apartment : activeApartment)
      }}>
      {transitions(({ opacity }, item) =>
        item ? (
          <a.img style={{ opacity: opacity }} src={FavActive} alt="" />
        ) : (
          <a.img style={{ opacity: opacity }} src={Fav} alt="" />
        ),
      )}
    </FavButton>
  )
}

const FavButton = styled(a.div)`
  pointer-events: auto;
  cursor: pointer;
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;

  & img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 1.4em;
    height: 1.4em;
  }
`
