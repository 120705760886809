import styled, { keyframes } from "styled-components/macro"
import BuildingExplorer from "components/BuildingExplorer"
import ApartmentFilter from "components/ApartmentFilter"
import ApartmentCard from "components/ApartmentCard/ApartmentCard"
import AscentaCard from "components/ApartmentCard/AscentaCard"
import AnimatedFrame from "components/AnimatedUIElements/AnimatedFrame/AnimatedFrame"
import AnimatedText from "components/AnimatedUIElements/AnimatedText/AnimatedText"
import BookmarkButton from "components/BookmarkButton"
import { useCallback, useRef } from "react"
import CompassIcon from "assets/images/icons/compass_svg.svg"
import { MathUtils } from "three"
import ApartmentCardMini from "components/ApartmentCardMini/ApartmentCardMini"
import { useEffect } from "react"
import { useStore } from "state/store"
import thorpFn from "throp/thorpFn"
import { useWebSocket } from "throp/WebSocketProvider"
import { useErrorContext } from "errors/ErrorProvider"
import ResidencesFloorplate from "components/ResidencesFloorplate"
import ascentaUnits from "dataset/ascentaUnits"
import { APART_PROPS } from "dataset/finder"

export default function Residences() {
  const rotationRef = useRef({ lat: 0, long: 0 })
  const compassRef = useRef()
  const plateRef = useRef()
  const compassCallback = useCallback(v => {
    let lat = MathUtils.radToDeg(v.theta)
    let long = MathUtils.radToDeg(v.phi)

    if (compassRef.current) {
      compassRef.current.style.transform = `rotate(${lat - 180}deg)`
      // if(plateRef.current){
      //   plateRef.current.style.transform = `rotate(${lat - 180}deg)`
      // }
      rotationRef.current.lat = lat
      rotationRef.current.long = long
    }
  }, [])

  const setInteracted = useStore(s => s.setInteracted)
  const activeApartment = useStore(s => s.activeApartment)
  const setActiveApartment = useStore(s => s.setActiveApartment)
  const setFilterVisible = useStore(s => s.setFilterVisible)
  const setActiveFloor = useStore(s => s.setActiveFloor)
  const setActiveElementID = useStore(s => s.setActiveElementID)
  const setModelsLoaded = useStore(s => s.setModelsLoaded)
  const { socket } = useWebSocket()
  const { error } = useErrorContext()
  const isAscenta = ascentaUnits.includes(activeApartment?.[APART_PROPS.unit])
  const handleCleanup = () => {
    setInteracted(false)
    setActiveFloor(null)
    setActiveElementID(null)
    setFilterVisible(true)
    setActiveApartment(null)
    setModelsLoaded(false)
  }

  useEffect(() => {
    return () => handleCleanup()
  }, [])

  return (
    <Holder onClick={() => setInteracted(true)} onTouchStart={() => setInteracted(true)}>
      <BookmarkButton />
      <UnmountRese />
      <BuildingHolder>
        <BuildingExplorer
          rotationRef={rotationRef}
          compassCallback={compassCallback}
          lightUpUnits={thorpFn.lightUpUnits}
          socket={socket}
        />
      </BuildingHolder>
      <ApartmentFilter />
      <ResidencesFloorplate isFav={false} ref={plateRef} />
      <ApartmentCardMini />
      {isAscenta ? <AscentaCard /> : <ApartmentCard />}
      <TitleHolder>
        <Title>
          <AnimatedText delay={200} text="LONDON LIVING" fontSize="3.9em" />
          <AnimatedText delay={1500} text="BEAUTIFULLY" fontSize="3.9em" />
          <AnimatedText delay={1900} text="ELEVATED" fontSize="3.9em" />
        </Title>
        <AnimatedFrame />
      </TitleHolder>
      <CompassUI>
        <North>N</North>
        <img ref={compassRef} alt="" src={CompassIcon} />
      </CompassUI>
      {error && (
        <ErrorNotification>
          There was a problem fetching the available apartments.
        </ErrorNotification>
      )}
    </Holder>
  )
}

// TODO handle apt light intensity on state and look at animating

function UnmountRese() {
  const setFullCardOpen = useStore(s => s.setFullCardOpen)

  const resetState = () => {
    setFullCardOpen(false)
  }

  useEffect(() => {
    return () => resetState()
  })

  return null
}

const North = styled.div`
  position: absolute;
  width: 100%;
  color: #b48465;
  font-family: Bliss-Light;
  display: flex;
  justify-content: center;
  transform: translate(0, -140%);
`

const CompassUI = styled.div`
  z-index: 2;
  position: fixed;
  right: 15%;
  bottom: 2em;
  min-width: 3.5em;
  max-width: 3.5em;
  min-height: 3.5em;
  max-height: 3.5em;
  box-sizing: border-box;
  border: #b48465 solid 2px;
  border: 100%;
  border-radius: 100%;

  & img {
    scale: 1.3;
  }
`

const Title = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 50vw;
  height: 50vh;

  padding: 0em;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  color: #e8e8e8;
`

const BuildingHolder = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: row;
  /* left: 2.5em; */
  width: 100%;
  height: 100%;
`

const TitleHolder = styled.div`
  padding: 3px;
  pointer-events: none;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 6em;
  width: 50vw;
  height: 67vh;
`

const Holder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Grande;
  font-weight: 100;
`

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const ErrorNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1em;
  color: white;
  font-family: Arial;
  padding: 1em;
  z-index: 100;
  background: rgba(180, 132, 101, 50%);
  animation: ${fade} 0.5s ease-in forwards, ${fadeOut} 0.5s ease-in 4.5s forwards;
`
