import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring } from "react-spring"
import { CloseWr } from "./ApartmentCard"
import { useStore } from "state/store"
import CloseButton from "./components/CloseButton"
import { cardANMSTYLE, parentCardANMSTYLE } from "./transitions/transitions"
import FloorplanAscent from "./components/FloorplanAscent"
import SpecificationsAscent from "./components/SpecificationsAscent"
import ViewsAscent from "./components/ViewsAscent"

function AscentaCard({apartment}) {
  let activeApartment = useStore(s => s.activeApartment)
  const fullCardOpen = useStore(s => s.fullCardOpen)
  const fullScreenViews = useStore(s => s.fullScreenViews)
  const setPanoViewerActive = useStore(s => s.setPanoViewerActive)
  const [section, setSection] = useState("FLOORPLAN")
  const parentAnm = useTransition(fullCardOpen, parentCardANMSTYLE)
  // const parentAnm = useTransition(true, parentCardANMSTYLE)
  const cardAnm = useSpring(cardANMSTYLE)
  const { opacity, width, height } = useSpring({
    opacity: fullScreenViews ? 0 : 1,
  })

  function changeSection(sec) {
    setSection(sec)
  }

  useEffect(() => {
    if (section === "VIEWS") {
      setPanoViewerActive(true)
    } else {
      setPanoViewerActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section])
  return parentAnm(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm}>
          <Card style={{ ...cardAnm, width: width, height: height }}>
            <FloorplanAscent section={section} action={changeSection} apartment={activeApartment} />
            <SpecificationsAscent
              section={section}
              action={changeSection}
              apartment={activeApartment}
            />
            <ViewsAscent
              section={section}
              action={changeSection}
              apartment={activeApartment}
            />
            <CloseWr
              style={{ opacity: opacity }}
              onClick={() => {
                setTimeout(() => setSection("FLOORPLAN"), 2000)
              }}
              className="close">
              <CloseButton />
            </CloseWr>
          </Card>
        </Wrapper>
      ),
  )
}

export default AscentaCard

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  z-index: 5;
  padding: 100px;

  .close {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 10%;
    aspect-ratio: 1/1;
    pointer-events: all;
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.5));
  }

  .ascent-plate {
    mask-image: radial-gradient(rgba(0,0,0,1) 40%, rgba(0,0,0,0) 85%, rgba(0,0,0,0));
    & g {
      &:nth-of-type(1) {
        & path {
          opacity: 0.3;
        }
        & g path {
          opacity: 1;
          fill: black;
        }
      }
      &:nth-of-type(2) {
        & g polygon {
          fill: white !important;
        }
        & g text {
          fill: black !important;
        }
      }
    }

    #active-poly {
      fill-opacity: 0.2;
      fill: #b58762 !important;
    }
  }
`

const Card = styled(a.div)`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  height: 90%;
  overflow: hidden;
`

const Display = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
