import React, { useState } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring } from "react-spring"

import FloorplanNew from "./components/Floorplan"
import SpecificationsNew from "./components/Specifications"
import Views from "./components/Views"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import { availability } from "utils/convertDataFormat"
import { useEffect } from "react"
import FavouriteButton from "./components/FavouriteButton"
import CloseButton from "./components/CloseButton"
import { cardANMSTYLE, parentCardANMSTYLE } from "./transitions/transitions"

function ApartmentCard() {
  let activeApartment = useStore(s => s.activeApartment)
  const fullCardOpen = useStore(s => s.fullCardOpen)
  const setPanoViewerActive = useStore(s => s.setPanoViewerActive)

  const [section, setSection] = useState("FLOORPLAN")

  useEffect(() => {
    if (section === "VIEWS") {
      setPanoViewerActive(true)
    } else {
      setPanoViewerActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section])

  const parentAnm = useTransition(fullCardOpen, parentCardANMSTYLE)

  const cardAnm = useSpring(cardANMSTYLE)

  function changeSection(sec) {
    setSection(sec)
  }

  const fullScreenViews = useStore(s => s.fullScreenViews)

  const { opacity, width, height } = useSpring({
    opacity: fullScreenViews ? 0 : 1,
  })

  return parentAnm(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm}>
          <Card style={{ ...cardAnm, width: width, height: height }}>
            <Display>
              <FloorplanNew section={section} action={changeSection} apartment={activeApartment} />
              <Views section={section} action={changeSection} />
              <SpecificationsNew section={section} action={changeSection} />
            </Display>
            <CloseWr
              style={{ opacity: opacity }}
              onClick={() => {
                setTimeout(() => setSection("FLOORPLAN"), 2000)
              }}
              className="close">
              <CloseButton />
            </CloseWr>
            <SideBarHolder style={{ opacity: opacity }}>
              <Header>
                <h1>
                  <span>&#9826; </span>
                  {activeApartment?.[APART_PROPS.unit]}
                  <span> &#9826;</span>
                </h1>
                <h3>{activeApartment?.[APART_PROPS.unitType]}</h3>
                <h3 style={{ letterSpacing: "0.3rem" }}>
                  {activeApartment &&
                    availability(
                      activeApartment["Availability"],
                      activeApartment[APART_PROPS.price],
                    )}
                </h3>
                <h3>
                  {activeApartment?.[APART_PROPS.beds] === "1"
                    ? `${activeApartment?.[APART_PROPS.beds]} BED`
                    : `${activeApartment?.[APART_PROPS.beds]} BEDS`}
                </h3>
                <FavouriteButton />
              </Header>
            </SideBarHolder>
          </Card>
        </Wrapper>
      ),
  )
}

export default ApartmentCard

const SideBarHolder = styled(a.span)``
export const CloseWr = styled(a.span)``

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  z-index: 5;
  padding: 100px;

  & span {
    filter: drop-shadow(5px 5px 15px black);
    pointer-events: none;
  }

  .close {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 10%;
    aspect-ratio: 1/1;
    pointer-events: all;
    filter: drop-shadow(-3px -3px 10px #b58762);
  }
`

const Card = styled(a.div)`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 90%;
  height: 90%;
`

const Header = styled.div`
  position: relative;
  left: 0;
  height: 100%;
  width: 23%;
  padding-bottom: 4%;
  clip-path: polygon(0% 0%, 15% 0%, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: black;
  color: #b58762;
  gap: 0.5em;

  & h1 {
    font-size: 3em;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    & span {
      font-size: 0.2em;
      font-weight: 100;
    }
  }

  & h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;

    :nth-of-type(1) {
      color: white;
      font-size: 1em;
    }
  }
`

const Display = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
