import apartmentsFn from "utils/apartmentsFn"

const APARTMENTS_URL = process.env.REACT_APP_APARTMENTS_URL

export async function getApartments() {
  try {
    const response = await fetch(APARTMENTS_URL)

    if (!response.ok) {
      throw new Error("Failed to fetch apartments")
    }

    return apartmentsFn.csvToJson(response)
  } catch (err) {
    console.log("Error on fetching apartments: ", err)

    return null
  }
}
