import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/web"
import {anm} from "../transitions/transitions"
import Viewer360 from "components/Viewer360/Viewer360"
import FullScreenIcon from "assets/images/icons/fullscreen.png"
import styled from "styled-components"
import { useStore } from "state/store"
import { useEffect } from "react"

function Views360({ section, activeApartment }) {
  const active = section === "VIEWS"
  const transition = useTransition(active, anm)

  const setFullScreenViews = useStore(s => s.setFullScreenViews)
  // const fullScreenViews = useStore(s => s.fullScreenViews)

  useEffect(() => {
    if (section !== "VIEWS") setFullScreenViews(false)
  }, [section])

  return transition(
    (styles, item) =>
      item && (
        <Viewer360Wr style={styles}>
          <Viewer360 activeApartment={activeApartment} active={active} />
        </Viewer360Wr>
      ),
  )
}

const Viewer360Wr = styled(a.div)`
  position: absolute;

  width: 100%;
  height: 100%;
`

export default Views360
