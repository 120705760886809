const specs = {
  default: {
    KITCHEN: {
      luminous: [
        `
        • Bespoke designed timber kitchens finished 
        in a light oak stain with pebble grey carcass`,
        `• Concrete finish composite stone worktop 
        with gunmetal undermounted sink
        `,
        `• Gunmetal finish boiling water tap `,
        `• Light tiled splashback `,
        `• Siemens or similar integrated appliances with visible 
        appliances in a black and anthracite finish
        `,
        `• Appliances include a single oven (1, 2 and 3 beds), 
        combination microwave and oven, warming drawer 
        (2 and 3 beds) induction hob, extractor, dishwasher 
        (slimline to studios), fridge freezer, wine fridge 
        (1, 2 and 3 beds)`,
      ],
      allure: [
        `
        • Bespoke designed timber kitchens finished in 
        a dark oak stain with graphite grey carcass`,
        `• Concrete finish composite stone worktop 
        with brushed gold undermounted sink
        `,
        `• Brushed finish gold boiling water tap `,
        `• Dark tiled splashback `,
        `• Siemens or similar integrated appliances with visible 
        appliances in a black and anthracite finish
        `,
        `• Appliances include a single oven (1, 2 and 3 beds), 
        combination microwave and oven, warming (2 and 
        3 beds) induction hob, extractor, dishwasher (slimline 
        to studios), fridge freezer, wine fridge (1, 2 and 3 beds)`,
      ],
    },
    BATHROOM: {
      allure: [
        `• Bespoke dark stained oak cabinetry both high and 
        low-level offering generous storage
        `,
        `• Full height tiling, where applicable, with a large format 
        tile below basin line and to the floor and a matching 
        linear tile above in a dark moody colour
        `,
        `• Tiled full height recess within bath/shower 
        enclosure with pin light above and glass shelving
        `,
        `• Recessed basin with concrete effect 
        composite stone vanity top
        `,
        `• Full height and width fitted mirror behind 
        basin with demister`,
      ],
      luminous: [
        `• Bespoke light-stained oak cabinetry both high and 
        low-level offering generous storage
        `,
        `• Full height tiling, where applicable, with a large format 
        tile below basin line and to the floor and a matching 
        linear tile above in a soft calm colour 
        `,
        `• Tiled full height recess within bath/shower enclosure 
        with pin light above and glass shelving
        `,
        `• Recessed basin with concrete effect composite 
        stone vanity top
        `,
        `• Full height and width fitted mirror behind basin 
        with demister`,
      ],
    },
    BALCONIES: [
      `
    • Inset balconies with stone effect floor finish tiles`,
      `• Metal cladding to balcony walls`,
      `• Powder-coated metal balustrade`,
    ],
    EXTERIOR: [
      `
    • External wall with high-quality precast concrete 
    panel and bronze finish cladding`,
      `• Reinforced concrete slab with appropriate floor 
    finishes`,
      `• Double-glazed aluminium windows including 
    opening lights, where appropriate, and doubleglazed aluminium doors to balconies and terraces 
    where appropriate 
    with durable steel powder-coated finish`,
      `• Steel powder-coated cladding and balustrade 
    with stone effect finish`,
    ],
    TEMPERATURE: [
      `
    • Zoned underfloor heating throughout supplied via 
    district heating network and centralised boilers`,
      `• Heated towel radiator to bathrooms and en-suites`,
      `• Comfort cooling to living room and bedroom one`,
      `• Mechanical ventilation with heat recovery`,
    ],
    COMMUNAL: [
      `
    • Entrance lobby with resident lounge 
    with soft seating and workspaces`,
      `• 24-hour concierge behind a bespoke timber 
    reception desk with stone countertop 
    and feature pendant lighting above`,
      `• Timber veneer post boxes to entrance 
    lobby with bronze detailing`,
      `• Three custom-designed residential lifts 
    with bronze aluminium doors `,
      `• Feature wayfinding signage`,
      `• Carpet to residential communal corridors`,
      `• In-situ or pre-cast concrete stairs with steel 
    balustrades in common areas `,
      `• 76 standard parking spaces, 9 motorbike 
    and 9 mobility spaces - available for select 
    apartments only`,
      `• Access to electric car charging from 
    all parking spaces`,
      `• 334 basement cycles spaces, plus space 
    for 7 mobility scooters`,
      `• Feature timber grain effect front entrance door 
    with matching surround benefitting from feature 
    external light and bronze door number `,
      `• Communal CCTV system `,
      `• Fob and video entry to main entrance 
    engaging automatic doors`,
    ],
    GENERAL: [
      `
    • Timber grain effect internal doors • Contemporary ironmongery in a matt 
    bronze finish`,
      `• Washer dryer in a separate cupboard`,
      `• Bespoke fitted wardrobe to principal and 
    second bedroom with internal LED lighting
   `,
      `• A choice of porcelain tile in a soft calm colour or 
    engineered wood in a dark tone throughout the 
    hall, 
    living, dining and kitchen areas
   `,
      `• Luxurious soft carpet to bedrooms finished in 
    stone colour with the option to upgrade to 
    porcelain tile or engineered wood
   `,
      `• Painted internal wall`,
    ],
  },

  chester: {
    "KITCHEN /PANTRY": [
      `• Bespoke painted oak kitchen cabinetry
      with soft close drawers`,
      `• Caesarstone worktop and splashback`,
      `• Miele 2 x single oven, combi oven, steam
      oven and warming drawer in black`,
      `• Bora induction hob with extractor in black`,
      `• Full height fridge and full height freezer
      with plumbed-in ice maker`,
      `• Black steel underslung sink with matt
      black boiling water tap`,
      `• Fisher & Paykel integrated double
      dishwasher drawers`,
    ],
    GENERAL: [
      ` • Matt black doorknobs, hinges, latches,
   and doorstops`,
      `• Caesarstone worktop and splashback`,
      `• Matt black lighting`,
      `• Matt black switches, sockets,
and media plate`,
      `• Dulux Pebble Shore to walls and ceilings`,
      `• Dulux Narrow Lane to shower room
and bathroom`,
      `• Havwoods Columba herringbone flooring
with colour matched doors and architraves
in oak stain finish`,
      `• Motorised blinds to all windows`,
      `• Govette glazed doors to pantry, walk
in wardrobe and bar area`,
    ],
    BAR: [
      `
    • Bespoke painted oak kitchen cabinetry
    with soft close drawers`,
      `• Caesarstone worktop`,
      `• Smoked mirror back panel with open
    shelving and LED lighting`,
      `• Fisher & Paykel integrated fridge
    freezer drawer`,
      `• Fully integrated Caple wine fridge`,
      `• Slim Caple black steel underslung sink
    with matt black tap`,
    ],
    PRINCIPAL: [
      `
    • Govette glazed cubicle doors for WC,
shower and storage cupboard`,
      `• Tadelakt polished plaster finish to walls
and ceiling in WC and shower cubicle,
including shower seat`,
      `• Wall hung shower WC`,
      `• Vola brassware in matt black including
floor standing taps, toilet roll holder and
brush, heated towel rail, robe hook`,
      `• Double freestanding basins with
suspended mirrors`,
      `• Double ended freestanding bath`,
      `• Large format Caesar Ceramiche floor
and wall tiles`,
    ],
    SHOWER: [
      `
    • Large format Caesar Ceramiche floor tiles
with vertical brick stacked wall tile`,
      `• Caesarstone Striata vanity drawer`,
      `• Black framed mirror above basin with
halo lighting`,
      `• Wall hung WC with soft close lid`,
      `• Walk in shower with glass
shower screen`,
      `• Matt black Crosswater brassware
including taps, thermostats, shower head,
hand rinse shower, flushplate and toilet
roll holder`,
      `• Glass shelves within niche to shower`,
      `• Heated towel rail`,
    ],
    BATHROOM: [
      `
    • Large format Caesar Ceramiche floor and
wall tiles with Caesarstone Striata vanity
top, below basin and to wall above basin`,
      `• Black framed mirror above basin with
feature wall lights either side`,
      `• Wall hung WC with soft close lid`,
      `• Double ended bath with shower above and
dec mounted handheld shower head`,
      `• Matt black Crosswater brassware including
taps, thermostats, shower head and
flushplate`,
      `• Shelved storage unit above WC`,
      `• Glass shelves within niche to shower`,
      `• Heated towel rail`,
    ],
    POWDER: [
      `
    • Tadelakt polished plaster to wall
    and ceilings`,
      `• Freestanding cylindrical basin`,
      `• Wall-mounted matt back tap, flush plate,
    toilet roll holder and heat towel rail`,
      `• Wall hung WC with soft close lid`,
      `• Fitted mirror with wall feature and
    wall-mounted lighting`,
    ],
  },
}

export default specs
