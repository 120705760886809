import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { a, useTransition } from "react-spring"
import { useDrag } from "react-use-gesture"

import ShareCard from "components/ApartmentCard/ShareCard"
import ShareCardAscenta from "components/ApartmentCard/ShareCardAscenta"
import getSwipeDirection from "utils/getSwipeDirection"

import { useStore } from "state/store"
import ascentaUnits from "dataset/ascentaUnits"
import { APART_PROPS } from "dataset/finder"

function ShareSwiper({ openState, favorites }) {
  const [currentCard, setCurrentCard] = useState(0)
  const setActiveApartment = useStore(s => s.setActiveApartment)
  const isAscenta = unit => ascentaUnits.includes(unit?.[APART_PROPS.unit])
  const wrapperTransition = useTransition(openState[0], {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  })

  const swipeDir = useRef(null)
  const [swiperActive, setSwiperActive] = useState(true)

  const drag = useDrag(
    e => {
      const direction = getSwipeDirection(e)
      swipeDir.current = !direction?.includes("right")
      if (e._lastEventType === "pointerup") {
        if (direction?.includes("left")) {
          currentCard === 0
            ? setCurrentCard(favorites?.length - 1)
            : setCurrentCard(currentCard - 1)
        } else {
          if (direction?.includes("right")) {
            currentCard === favorites?.length - 1
              ? setCurrentCard(0)
              : setCurrentCard(currentCard + 1)
          }
        }
      }
    },
    { enabled: swiperActive },
  )

  useEffect(() => {
    setActiveApartment(favorites[currentCard])
  }, [currentCard])

  return wrapperTransition(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm} {...drag()}>
          {favorites.map((fav, i) => {
            return isAscenta(fav) ? (
              <ShareCardAscenta
                apartment={fav}
                key={`sharedCard${i}`}
                isOpen={currentCard === i}
                direction={swipeDir.current}
                setSwiperActive={setSwiperActive}
                action={openState[1]}
              />
            ) : (
              <ShareCard
                apartment={fav}
                key={`sharedCard${i}`}
                isOpen={currentCard === i}
                direction={swipeDir.current}
                setSwiperActive={setSwiperActive}
                action={openState[1]}
              />
            )
          })}
          {/* {swiperActive && <Icon src={`${process.env.PUBLIC_URL}/assets/images/share/swipe.gif`} alt="swipe-icon" />} */}
        </Wrapper>
      ),
  )
}

export default ShareSwiper

const Wrapper = styled(a.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* background-color: rgba(255, 255, 255, 0.5); */
`

const Icon = styled.img`
  position: absolute;
  z-index: 9;
  max-width: 3rem;
  left: 50%;
  bottom: 3%;
  transform: translateX(-50%);
`
