import React from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"
import {  TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

import { BEDLABELS, FloorPlateWrapper, Header, Lines } from "./FloorplanAscent"
import { anm } from "../transitions/transitions"
import { APART_PROPS } from "dataset/finder"
import SectionsAscent from "./SectionsAscent"
import FloorPlate from "components/FloorPlate/FloorPlate"

function ViewsAscent({ section, action, apartment }) {
  const transition = useTransition(section === "VIEWS", anm)
  const lineHeight = window.innerHeight ?? 600
  const lineWidth = lineHeight / 3
  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          <div className="viewWrapper">
            <TransformWrapper
              centerOnInit={true}
              initialScale={1.4}
              minScale={1}
              maxScale={2}
              limitToBounds={true}
              smooth={true}
              centerZoomedOut={true}>
              <TransformComponent wrapperClass="pinchZoom" contentClass="pinchZoom">
                <Image route={apartment[APART_PROPS.unit]} className="centerABS" />
              </TransformComponent>
            </TransformWrapper>
          </div>
          <Lines viewBox={`0 0 ${lineWidth} ${lineHeight}`} style={{ zIndex: 2 }}>
            <polyline
              stroke="#b58762"
              strokeOpacity={0.3}
              strokeWidth={2}
              points={`${lineWidth * 0.5},0 ${lineWidth * 0.5},${lineHeight * 0.583}`}
              vectorEffect="non-scaling-stroke"
            />
            <polyline
              stroke="#b58762"
              strokeOpacity={0.3}
              strokeWidth={2}
              points={`0,${lineHeight} ${lineWidth * 0.5},${
                lineHeight * 0.583
              } ${lineWidth},${lineHeight}`}
              fill="white"
              vectorEffect="non-scaling-stroke"
            />
          </Lines>
          <SideBar>
            <Header>
              <h3>ASCENTA</h3>
              <h1>
                N° <span>{apartment[APART_PROPS.unit]}</span>
              </h1>
              <h4>{BEDLABELS[apartment[APART_PROPS.beds]]}</h4>
            </Header>
            <FloorPlateWrapper className="ascent-plate">
              <FloorPlate
                apartment={apartment}
                floor={apartment[APART_PROPS.floor]}
                isFav={false}
              />
            </FloorPlateWrapper>
            <SectionsWrapper>
              <SectionsAscent action={action} section={section} apartment={apartment} />
            </SectionsWrapper>
          </SideBar>
        </Wrapper>
      ),
  )
}

export default ViewsAscent

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;

  .viewWrapper {
    position: absolute;
    right: 0;
    width: 70%;
    height: 100%;
    overflow: hidden;
  }

  .pinchZoom {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .centerABS {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
`

const Image = styled.div`
  background-image: ${p => `url(assets/images/finder/views/still/${p.route}.webp)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const SideBar = styled.div`
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
  padding: 1.5%;
  gap: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SectionsWrapper = styled.div`
  width: 60%;
`
