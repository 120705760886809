import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import { BEDLABELS, Header, Lines } from "./FloorplanAscent"
import { anm } from "../transitions/transitions"
import { APART_PROPS } from "dataset/finder"
import { ascentaFinishes } from "dataset/ascentaUnits"
import SectionsAscent from "./SectionsAscent"
import Right from "assets/images/icons/right.svg"
import loopArrayIndex from "utils/loopArray"

function SpecificationsAscent({ section, action, apartment }) {
  let activeApartment = apartment
  const specsArr = ["Kitchen", "Bedrooms", "Bathrooms", "Ensuites"]
  const [selectedSpec, setSelectedSpec] = useState(specsArr[0])
  const [selectedImg, setSelectedImg] = useState(0)
  const transition = useTransition(section === "SPECIFICATION", anm)
  const finish = ascentaFinishes[activeApartment?.[APART_PROPS.unit]]
  const lineHeight = window.innerHeight ?? 600
  const lineWidth = lineHeight / 3
  const imagesArr = images[finish][selectedSpec]

  const handleClick = useCallback(
    dir => {
      const nextIndex = loopArrayIndex(dir, images[finish][selectedSpec], selectedImg)
      setSelectedImg(nextIndex)
    },
    [imagesArr, selectedImg],
  )

  useLayoutEffect(() => {
    setSelectedImg(0)
  }, [apartment, selectedSpec, finish])

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          {imagesArr.map((src, i) => {
            return (
              <Image
                $route={src}
                key={`${finish}-img-${i}`}
                style={{ opacity: selectedImg === i ? 1 : 0 }}
              />
            )
          })}
          <Controls>
            <div onClick={() => handleClick(false)}>
              <img src={Right} />
            </div>
            <div onClick={() => handleClick(true)}>
              <img src={Right} />
            </div>
          </Controls>
          <Lines viewBox={`0 0 ${lineWidth} ${lineHeight}`} style={{ zIndex: 2 }}>
            <polyline
              stroke="#b58762"
              strokeOpacity={0.3}
              strokeWidth={2}
              points={`${lineWidth * 0.5},0 ${lineWidth * 0.5},${lineHeight * 0.583}`}
              vectorEffect="non-scaling-stroke"
            />
            <polyline
              stroke="#b58762"
              strokeOpacity={0.3}
              strokeWidth={2}
              points={`0,${lineHeight} ${lineWidth * 0.5},${
                lineHeight * 0.583
              } ${lineWidth},${lineHeight}`}
              fill="white"
              vectorEffect="non-scaling-stroke"
            />
          </Lines>
          <SideBar>
            <SpecsInfo>
              <Header>
                <h3>ASCENTA</h3>
                <h1>
                  N° <span>{activeApartment[APART_PROPS.unit]}</span>
                </h1>
                <h4>{BEDLABELS[activeApartment[APART_PROPS.beds]]}</h4>
              </Header>
            </SpecsInfo>
            <div>
              {specsArr.map((spec, i) => {
                return (
                  <SpecsOption
                    style={{
                      color: spec == selectedSpec ? "#b58762" : "black",
                    }}
                    onClick={() => {
                      setSelectedSpec(spec)
                    }}
                    key={`opt${i}`}>
                    {spec}
                  </SpecsOption>
                )
              })}
            </div>
            <SectionsWrapper>
              <SectionsAscent action={action} section={section} apartment={activeApartment} />
            </SectionsWrapper>
          </SideBar>
        </Wrapper>
      ),
  )
}

export default SpecificationsAscent

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background-image: url(${p => p.$route});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
`

const Controls = styled.div`
  position: absolute;
  width: 70%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  & div {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    padding: 0.1rem;
    border: 0.1rem solid #b58762;
    border-radius: 100%;
    cursor: pointer;

    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-of-type(1) {
      transform: scaleX(-1);
    }
  }
`

const SideBar = styled.div`
  position: absolute;
  left: 0;
  width: 30%;
  height: 100%;
  padding: 1.5%;
  gap: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SpecsInfo = styled.div`
  color: black;
  position: relative;
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 1.5em;
    text-align: center;
    font-weight: 100;
  }
`

const SectionsWrapper = styled.div`
  width: 60%;
`

const SpecsOption = styled.p`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out;
  height: 2.5em;
`

const baseURL = "assets/images/finder/specs/"
export const images = {
  Allure: {
    Kitchen: [
      `${baseURL}Allure/kitchen-1.webp`,
      `${baseURL}Allure/kitchen-2.webp`,
      `${baseURL}Allure/kitchen-3.webp`,
      `${baseURL}Allure/kitchen-4.webp`,
    ],
    Bedrooms: [
      `${baseURL}Allure/bedroom-1.webp`,
      // `${baseURL}Allure/bedroom-2.webp`
    ],
    Bathrooms: [
      `${baseURL}Allure/bathroom-1.webp`,
      `${baseURL}Allure/bathroom-2.webp`,
      `${baseURL}Allure/bathroom-3.webp`,
      `${baseURL}Allure/bathroom-4.webp`,
    ],
    Ensuites: [`${baseURL}Allure/ensuite-1.webp`, `${baseURL}Allure/ensuite-2.webp`],
  },
  Luminous: {
    Kitchen: [
      `${baseURL}Luminous/kitchen-1.webp`,
      `${baseURL}Luminous/kitchen-2.webp`,
      `${baseURL}Luminous/kitchen-3.webp`,
    ],
    Bedrooms: [
      `${baseURL}Luminous/bedroom-1.webp`,
      `${baseURL}Luminous/bedroom-2.webp`,
      `${baseURL}Luminous/bedroom-3.webp`,
    ],
    Bathrooms: [
      `${baseURL}Luminous/bathroom-1.webp`,
      `${baseURL}Luminous/bathroom-2.webp`,
      `${baseURL}Luminous/bathroom-3.webp`,
    ],
    Ensuites: [`${baseURL}Luminous/ensuite-1.webp`],
  },
}
