import React, { useState } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import anm from "./transitions/transitions"
import { APART_PROPS } from "dataset/finder"
import dimensions from "dataset/dimensions"

function FloorplanMobile({ section, apartment, isAscenta = false }) {
  let activeApartment = apartment
  const transition = useTransition(section === "FLOORPLAN", anm)
  const [display, setDisplay] = useState(true)

  const route = activeApartment?.[APART_PROPS.unitType]?.split(" ")[1]?.toLowerCase()
  const aptDimensions = route && Object.keys(dimensions?.[route])

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          {!isAscenta && <Line />}
          {/* {display ? ( */}
          <Plan
            src={
              isAscenta
                ? `assets/images/finder/plans/${apartment[APART_PROPS.unit]}.png`
                : `assets/images/finder/plans/${route}.svg`
            }
            onClick={() => {
              setDisplay(!display)
            }}
          />
          {/* ) : ( */}
          <DimensionsInfo
            className={isAscenta ? "ascenta" : ""}
            onClick={() => {
              setDisplay(!display)
            }}>
            <ul>
              {aptDimensions?.map((key, i) => {
                return (
                  <li key={`li${i}`}>
                    {!isAscenta && <h4> {i + 1} </h4>}
                    <p>
                      <span>{key}</span>
                      {route && dimensions[route]?.[key]}
                    </p>
                  </li>
                )
              })}
            </ul>
          </DimensionsInfo>
          {/* )} */}
          {!isAscenta && <Line />}
        </Wrapper>
      ),
  )
}

export default FloorplanMobile

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  touch-action: auto !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  touch-action: pan-y;

  > * {
    touch-action: pan-y;
  }

  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-track {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    border: none;
    outline: none;
    background-color: rgb(181, 135, 98);
  }

  .ascenta {
    color: black;
    font-family: Bliss-Light;
    display: flex;
    align-items: center;
    justify-content: center;

    & li {
      & p {
        font-family: Bliss-Light;
        font-size: 2em;
        text-align: center;

        & span {
          font-family: Bliss-Light;
          filter: none;
        }
      }
    }
  }
`

const Plan = styled.img`
  width: 100%;
  height: 35vh;
  background-color: white;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

const DimensionsInfo = styled.div`
  color: white;
  position: relative;
  width: 100%;
  max-width: 100%;
  pointer-events: none;

  & h1 {
    font-size: 3em;
    text-align: center;
    font-weight: 100;
  }

  & ul {
    list-style: none;
  }

  & li {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 5% 5%;
    gap: 5%;

    & h4 {
      width: 10%;
      aspect-ratio: 1/1;
      border: 1px solid #b58762;
      font-size: 2em;
      color: #b58762;
      display: grid;
      place-content: center;
      border-radius: 100%;
    }

    & p {
      display: flex;
      flex-direction: column;
      font-weight: 100;
      justify-content: center;
      font-family: Bliss-Light;
      font-size: 2em;

      & span {
        color: #b58762;
        font-weight: 100;
        font-family: Grande;
        font-size: 1.2em;
      }
    }
  }
`

const Line = styled.hr`
  width: 80%;
  height: 1px;
  margin: 5% 10%;
  background-color: white;
  border: none;
`
