import { useTexture } from "@react-three/drei"
import { useEffect, useRef, useMemo } from "react"
import { meshVertIds } from "dataset/meshVertIds.js"
import { meshFaceIds } from "dataset/meshFaceIds.js"
import RoomLightsMaterial from "../materials/RoomLightsMaterial"
import { BufferAttribute } from "three"
import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import { useApartments } from "utils/useApartments"
import { useGLTF } from "utils/hooks/useGLTF"
import useDidMountEffect from "throp/useDidMountEffect"

function Apartments({ model, texture, socket, lightUpUnits }) {
  return (
    <group>
      <BakedMeshElement
        model={model}
        texture={texture}
        name="flat"
        socket={socket}
        lightUpUnits={lightUpUnits}
      />
    </group>
  )
}

function BakedMeshElement({ model, name, path, texture, socket, lightUpUnits }) {
  const setFullCardOpen = useStore(s => s.setFullCardOpen)
  const activeApartment = useStore(state => state.activeApartment)
  const setActiveApartment = useStore(state => state.setActiveApartment)
  const setFilterVisible = useStore(state => state.setFilterVisible)
  const setActiveFloor = useStore(state => state.setActiveFloor)
  const activeFloor = useStore(state => state.activeFloor)
  const setActiveElementID = useStore(state => state.setActiveElementID)
  const filterVisible = useStore(state => state.filterVisible)

  const { nodes } = useGLTF(model)

  const difMap = useTexture(texture)
  difMap.flipY = false

  const nodesArr = Object.values(nodes)
  const meshRef = useRef()
  const meshIdArr = useRef(null)

  const { all, availableApartments, availableIDs } = useApartments()

  const floorsIndex = useMemo(() => {
    let arr = []
    if (activeFloor) {
      availableApartments.forEach(ap => {
        if (ap[APART_PROPS.floor] === activeFloor.toString()) {
          arr.push(parseInt(ap[APART_PROPS.unitNumber].split(".")[1]))
        }
      })
    }
    return arr
  }, [activeFloor, availableApartments])

  useEffect(() => {
    const bufferGeometry = meshRef.current.geometry
    const newArr = []
    const attrib = bufferGeometry.getAttribute("position")
    const count = attrib.count

    for (let i = 0; i < count; i++) {
      if (availableIDs.includes(meshVertIds[i])) {
        newArr.push(0)
      } else {
        newArr.push(meshVertIds[i])
      }
    }

    const array = new Float32Array(newArr)
    meshIdArr.current = array

    const attribute = new BufferAttribute(array, 1)
    bufferGeometry.setAttribute("IDAtt", attribute)
  }, [availableIDs])

  useEffect(() => {
    const bufferGeometry = meshRef.current.geometry
    const newArr = []
    const attrib = bufferGeometry.getAttribute("position")
    const count = attrib.count

    for (let i = 0; i < count; i++) {
      if (floorsIndex.includes(meshVertIds[i]) || filterVisible) {
        newArr.push(meshVertIds[i])
      } else {
        newArr.push(0)
      }
    }

    const array = new Float32Array(newArr)
    meshIdArr.current = array

    const attribute = new BufferAttribute(array, 1)
    bufferGeometry.setAttribute("floorAtr", attribute)
  }, [activeFloor, availableIDs, filterVisible, floorsIndex, activeApartment])

  useEffect(() => {
    const bufferGeometry = meshRef.current.geometry
    const array = new Float32Array(meshVertIds)

    meshIdArr.current = array

    const attribute = new BufferAttribute(array, 1)
    bufferGeometry.setAttribute("vertID", attribute)
  }, [])

  useDidMountEffect(() => {
    const apartmentsOnFloor = availableApartments.filter(
      apt => apt[APART_PROPS.floor] === activeFloor,
    )

    if (apartmentsOnFloor && apartmentsOnFloor.length > 0) {
      lightUpUnits(socket, apartmentsOnFloor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFloor])

  useDidMountEffect(() => {
    if (activeApartment) {
      lightUpUnits(socket, activeApartment)
    }
  }, [activeApartment])

  useDidMountEffect(() => {
    if (availableApartments && availableApartments.length > 0 && !activeApartment && !activeFloor) {
      lightUpUnits(socket, availableApartments)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableApartments])

  const handleClickApartments = e => {
    e.stopPropagation()
    const thisID = meshFaceIds[e.faceIndex] - 1
    const thisApt = all[thisID]
    setFilterVisible(false)

    // if (thisApt[APART_PROPS.floor] !== "22") {
    setFilterVisible(false)

    if (thisApt?.[APART_PROPS.unit] === activeApartment?.[APART_PROPS.unit]) setFullCardOpen(true)

    if (thisApt[APART_PROPS.floor] === activeFloor) {
      // console.log("SET", meshFaceIds[e.faceIndex])
      setActiveElementID(meshFaceIds[e.faceIndex])
      setActiveApartment(thisApt)
    } else {
      setActiveFloor(thisApt[APART_PROPS.floor])
      setActiveElementID(null)
      setActiveApartment(null)
    }
    // }
  }

  return (
    <group>
      {nodesArr.map((n, i) => (
        <mesh
          onClick={e => {
            handleClickApartments(e)
          }}
          ref={meshRef}
          key={n.name}
          geometry={n.geometry}>
          <RoomLightsMaterial map={difMap} />
        </mesh>
      ))}
    </group>
  )
}

export default Apartments
