import { useTexture } from "@react-three/drei"
import { useEffect, useState } from "react"
import { useTransition } from "@react-spring/core"
import { animated } from "@react-spring/three"
import AnimatedBakedShaderMaterial from "../materials/AnimatedBakedShaderMaterial"
import { DoubleSide, sRGBEncoding } from "three"
import { useStore } from "state/store"
import { useGLTF } from "utils/hooks/useGLTF"

function Building({ models, textures }) {
  const modelsReady = []

  const setModelsLoaded = useStore(s => s.setModelsLoaded)

  const addModels = v => {
    modelsReady.push(v)

    if (modelsReady.length === 15) {
      setModelsLoaded(true)
    }
  }

  useEffect(() => {
    return () => {
      setModelsLoaded(false)
    }
  })


  return (
    <group>
      {/* <BasePlate addModels={addModels} /> */}
      <ConcreteFacade
        model={models.concrete}
        texture={textures.concreteimg}
        addModels={addModels}
      />
      <Glass model={models.glass} addModels={addModels} />
      <Brass model={models.brass} addModels={addModels} />

      <Black model={models.black} addModels={addModels} />
      <BlackRail model={models.blackrail} addModels={addModels} />
    </group>
  )
}

// function BasePlate({ addModels }) {
//   return <BakedMeshElement addModels={addModels} intensity={0.1} name="base" />
// }
function ConcreteFacade({ model, addModels, texture }) {
  return (
    <BakedMeshElement
      addModels={addModels}
      model={model}
      texture={texture}
      intensity={0.2}
      name="concrete"
    />
  )
}

function Glass({ addModels, model }) {
  return <MeshElement model={model} addModels={addModels} name="glass" material={GlassMaterial} />
}

function BlackRail({ addModels, model }) {
  return (
    <MeshElement
      model={model}
      addModels={addModels}
      name="blackrail"
      material={BlackRailMaterial}
    />
  )
}
function Black({ addModels, model }) {
  return <MeshElement model={model} addModels={addModels} name="black" material={BlackMaterial} />
}

function Window({ addModels, model }) {
  return <MeshElement model={model} addModels={addModels} name="window" material={WindowMaterial} />
}

function Brass({ addModels, model }) {
  return <MeshElement model={model} addModels={addModels} name="brass" material={BrassMaterial} />
}
const GlassMaterial = {
  roughness: 0.06,
  ior: 3.33,
  metalness: 0.7,
  opacity: 0.2,
  transparent: 1,
  color: "#b3b3b3",
  flatShading: true,
}

const BlackRailMaterial = {
  roughness: 0.2,
  reflection: 0.8,
  ior: 2,
  metalness: 1,
  transparent: 1,
  refraction: 1,
  color: "#000",
  side: DoubleSide,
  flatShading: true,
}
const BlackMaterial = {
  roughness: 0.2,
  reflection: 0.8,
  ior: 2,
  metalness: 1,
  transparent: 1,
  refraction: 1,
  color: "#000",
  side: DoubleSide,
  flatShading: true,
}
const WindowMaterial = {
  roughness: 0.9,
  reflection: 0.8,
  ior: 1.2,
  metalness: 0.2,
  transparent: 1,
  refraction: 1,
  color: "#000",
  side: DoubleSide,
  flatShading: true,
}

const BrassMaterial = {
  roughness: 0.5,
  reflection: 0.5,
  ior: 11.8,
  metalness: 0.9,
  transparent: true,
  color: "#deb381",
  flatShading: true,
}

function MeshElement({ name, material, model, addModels }) {
  // const { nodes } = useGLTF(`assets/models/${name}.glb`)
  const { nodes } = useGLTF(model)
  const nodesArr = Object.values(nodes)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (nodesArr) {
      addModels(name)
      setLoaded(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesArr])

  const transition = useTransition(loaded, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
  })

  return transition(
    ({ opacity }, item) =>
      item && (
        <group>
          {nodesArr.map((n, i) => (
            <mesh key={n.name} geometry={n.geometry}>
              <animated.meshPhysicalMaterial opacity={opacity} {...material} />
            </mesh>
          ))}
        </group>
      ),
  )
}
function BakedMeshElement({ name, texture, model, addModels }) {
  const { nodes } = useGLTF(model)
  const difMap = useTexture(texture)
  const nodesArr = Object.values(nodes)

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (nodesArr && difMap) {
      addModels(name)
      setLoaded(true)
    }
  }, [nodesArr, difMap])

  difMap.flipY = false
  difMap.encoding = sRGBEncoding

  const transition = useTransition(loaded, {
    from: { opacity: 0, delay: 0 },
    enter: { opacity: 1, delay: 0 },
    leave: { opacity: 0, delay: 0 },
  })

  return transition(
    ({ opacity }, item) =>
      item && (
        <group>
          {nodesArr.map((n, i) => (
            <mesh key={n.name} geometry={n.geometry}>
              <AnimatedBakedShaderMaterial opacity={opacity} map={difMap} />
            </mesh>
          ))}
        </group>
      ),
  )
}

export default Building
