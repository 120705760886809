import styled from "styled-components"

import { ascentaFinishes } from "dataset/ascentaUnits"
import Right from "assets/images/icons/right.svg"
import FavouriteButton from "./FavouriteButton"
import { APART_PROPS } from "dataset/finder"
import { useLocation } from "react-router-dom"

function SectionsAscent({ action, section, apartment }) {
  const sections = [
    { section: "FLOORPLAN", label: `N° ${apartment?.[APART_PROPS.unit]} floorplan` },
    { section: "VIEWS", label: "Discover the views" },
    {
      section: "SPECIFICATION",
      label: `The ${ascentaFinishes[apartment?.[APART_PROPS.unit]]} palette`,
    },
  ]
  const { pathname } = useLocation()
  return (
    <Wrapper>
      <List>
        {sections.map(obj => {
          return (
            <ListElement
              key={`${obj.section}-${section}`}
              style={{
                color: obj.section === section ? "rgba(181, 135, 98, 1)" : "black",
              }}
              onClick={() => {
                action(obj.section)
              }}>
              {" "}
              {obj.label}
            </ListElement>
          )
        })}
        {pathname?.includes("share") ? null : <FavouriteButton apartment={apartment} />}
      </List>
    </Wrapper>
  )
}

export default SectionsAscent

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const List = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  padding-bottom: 0%;
  color: black;
  gap: 20px;
`

const ListElement = styled.li`
  width: 70%;
  padding: 0.5em;
  display: grid;
  place-content: center;
  font-size: 0.8em;
  height: 2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: color;
  transition-duration: 0.4s;
  transition-delay: 0.8s;
  transition-timing-function: ease-in-out;
  text-align: center;
`
