/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

import anm from "./transitions/transitions"
import Views360 from "components/ApartmentCard/components/Views360"
import { APART_PROPS } from "dataset/finder"

function ViewsMobile({ section, apartment, isAscenta = false }) {
  const transition = useTransition(section === "VIEWS", anm)

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          {isAscenta ? (
            <div className="viewWrapper">
              <TransformWrapper
                centerOnInit={true}
                initialScale={1.6}
                minScale={1}
                maxScale={2}
                limitToBounds={true}
                smooth={true}
                centerZoomedOut={true}>
                <TransformComponent wrapperClass="pinchZoom" contentClass="pinchZoom">
                  <Image route={apartment[APART_PROPS.unit]} className="centerABS" />
                </TransformComponent>
              </TransformWrapper>
            </div>
          ) : (
            <Views360 activeApartment={apartment} section={section} />
          )}
        </Wrapper>
      ),
  )
}

export default ViewsMobile

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  .viewWrapper {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .pinchZoom {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .centerABS {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
`
const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${p => `url(assets/images/finder/views/still/${p.route}.webp)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: white;
`
