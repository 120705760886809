import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring } from "react-spring"

import FloorplanNew from "./components/Floorplan"
import SpecificationsNew from "./components/Specifications"
import CloseIcon from "assets/images/icons/close.svg"
import Views from "./components/Views"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import { availability } from "utils/convertDataFormat"

function ShareCard({ apartment, isOpen, action, direction, setSwiperActive }) {
  const favorites = useStore(s => s.favorites)
  const isFav = favorites.includes(apartment)
  const [section, setSection] = useState("FLOORPLAN")

  const parentAnm = useTransition(
    isOpen,
    direction
      ? {
          from: {
            opacity: 0,
            left: "0%",
            filter: "blur(5px)",
          },
          enter: {
            opacity: 1,
            left: "50%",
            filter: "blur(0px)",
            delay: 300,
          },
          leave: {
            opacity: 0,
            left: "100%",
            filter: "blur(5px)",
          },
        }
      : {
          from: {
            opacity: 0,
            left: "100%",
            filter: "blur(5px)",
          },
          enter: {
            opacity: 1,
            left: "50%",
            filter: "blur(0px)",
            delay: 300,
          },
          leave: {
            opacity: 0,
            left: "0%",
            filter: "blur(5px)",
          },
        },
  )

  const cardAnm = useSpring({
    from: {
      opacity: 1,
      top: "0%",
    },
    to: {
      opacity: 1,
      top: "50%",
    },
  })

  function changeSection(sec) {
    setSection(sec)
  }

  useEffect(() => {
    setSwiperActive(section !== "VIEWS")
  }, [section])
  return parentAnm(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm}>
          <Card style={cardAnm}>
            <Display>
              <FloorplanNew isFav section={section} action={changeSection} apartment={apartment} />
              <Views isFav section={section} action={changeSection} apartment={apartment} />
              <SpecificationsNew
                isFav
                section={section}
                action={changeSection}
                apartment={apartment}
              />
            </Display>
            <span>
              <Header>
                <h1>
                  <span>&#9826; </span>
                  {apartment?.[APART_PROPS.unit]}
                  <span> &#9826;</span>
                </h1>
                <h3>{apartment?.[APART_PROPS.unitType]}</h3>
                <h3 style={{ letterSpacing: "0.3rem" }}>
                  {apartment &&
                    availability(apartment["Availability"], apartment[APART_PROPS.price])}
                </h3>
                <h3>
                  {apartment?.[APART_PROPS.beds] === "1"
                    ? `${apartment?.[APART_PROPS.beds]} BED`
                    : `${apartment?.[APART_PROPS.beds]} BEDS`}
                </h3>
              </Header>
            </span>
          </Card>
        </Wrapper>
      ),
  )
}

export default ShareCard

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  & span {
    filter: drop-shadow(5px 5px 15px black);
    pointer-events: none;
  }

  .close {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 10%;
    aspect-ratio: 1/1;
    pointer-events: all;
    filter: drop-shadow(-3px -3px 10px #b58762);
  }
`

const Card = styled(a.div)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
`

const Header = styled.div`
  position: relative;
  left: 0;
  height: 100%;
  width: 25%;
  padding-bottom: 2%;
  clip-path: polygon(0% 0%, 15% 0%, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black;
  color: #b58762;
  gap: 0.5em;

  & h1 {
    font-size: 3em;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    & span {
      font-size: 0.2em;
      font-weight: 100;
    }
  }

  & h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;

    :nth-of-type(1) {
      color: white;
      font-size: 1em;
    }
  }
`

const Display = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Close = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
  background-color: white;

  & img {
    position: absolute;
    bottom: 10%;
    right: 10%;
    z-index: 5;
    width: 35%;
    aspect-ratio: 1/1;
    border: 1px solid #b58762;
    border-radius: 100%;
    padding: 5%;
  }
`
