/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react"
import styled from "styled-components"
import PageTransitionHolder from "components/PageTransitionHolder"
import { useLocation } from "react-router-dom"

import { APART_PROPS } from "dataset/finder"
import { useStore } from "state/store"
import { useMobileOrientation, isMobileOnly} from "react-device-detect"

import ShareSwiper from "components/ShareSwiper/ShareSwiper"
import ShareMobile from "./mobile/ShareMobile"

export default function Share() {
  const location = useLocation()
  const codedFav = location.search
  const decodedFav = atob(codedFav.substring(7)).split(",")
  const apartments = useStore(s => s.apartments)
  const favorites = apartments.filter(apt => decodedFav.some(fav => fav === apt[APART_PROPS.unit]))
  const [swiperOpen, setSwiperOpen] = useState(true)
  const orientation = useMobileOrientation().orientation

  return isMobileOnly ? (
    <ShareMobile swiperState={[swiperOpen, setSwiperOpen]} favorites={favorites} />
  ) : (
    <Fragment>
      <PageTransitionHolder>
        <Holder>
          <ShareSwiper openState={[swiperOpen, setSwiperOpen]} favorites={favorites} />
        </Holder>
      </PageTransitionHolder>
      <Blocker
        style={{
          display: orientation === "portrait" ? "block" : "none",
        }}>
        Please use <br />
        in landscape mode.
      </Blocker>
    </Fragment>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: #000;

  .ascent-plate {
    & g {
      &:nth-of-type(1) {
        & path {
          opacity: 0.3;
        }
        & g path {
          opacity: 1;
          fill: black;
        }
      }
    }
  }
`

const Blocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(infinity);
  width: 100vw;
  height: 100vh;
  display: none;
  color: #b58762;
  background-color: black;
  font-family: Bliss-Regular;
  display: grid;
  place-content: center;
  text-align: center;
  font-size: 2rem;
`
