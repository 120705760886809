import React, { useLayoutEffect, useState, useCallback } from "react"
import { a, useTransition } from "react-spring"
import styled from "styled-components"

import anm from "./transitions/transitions"
import data from "dataset/specs.js"
import { ascentaFinishes } from "dataset/ascentaUnits"
import { APART_PROPS } from "dataset/finder"
import Right from "assets/images/icons/right.svg"
import loopArrayIndex from "utils/loopArray"
import { images } from "components/ApartmentCard/components/SpecificationsAscent"

function SpecsMobileAscenta({ section, apartment }) {
  const specsArr = ["Kitchen", "Bedrooms", "Bathrooms", "Ensuites"]
  const [selectedSpec, setSelectedSpec] = useState(specsArr[0])
  const [selectedImg, setSelectedImg] = useState(0)
  const transition = useTransition(section === "SPECS", anm)
  const finish = ascentaFinishes[apartment?.[APART_PROPS.unit]]
  const imagesArr = images[finish][selectedSpec]

  const handleClick = useCallback(
    dir => {
      const nextIndex = loopArrayIndex(dir, images[finish][selectedSpec], selectedImg)
      setSelectedImg(nextIndex)
    },
    [imagesArr, selectedImg],
  )

  useLayoutEffect(() => {
    setSelectedImg(0)
  }, [apartment, selectedSpec, finish])

  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={styles}>
          <CopyWrapper>
            {specsArr.map((spec, i) => {
              return (
                <SpecsOption
                  style={{
                    color: spec == selectedSpec ? "#b58762" : "black",
                  }}
                  onClick={() => {
                    setSelectedSpec(spec)
                  }}
                  key={`opt${i}`}>
                  {spec}
                </SpecsOption>
              )
            })}
          </CopyWrapper>
          {imagesArr.map((src, i) => {
            return (
              <Image
                $route={src}
                key={`${finish}-img-${i}`}
                style={{ opacity: selectedImg === i ? 1 : 0 }}
              />
            )
          })}
          <Controls>
            <div onClick={() => handleClick(false)}>
              <img src={Right} />
            </div>
            <div onClick={() => handleClick(true)}>
              <img src={Right} />
            </div>
          </Controls>
        </Wrapper>
      ),
  )
}

export default SpecsMobileAscenta

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const Image = styled.div`
  position: absolute;
  width: 100%;
  height: 90%;
  background-image: ${p => `url(${p.$route})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
`

const Controls = styled.div`
  position: absolute;
  width: 100%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  opacity: 0.7;

  & div {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    padding: 0.1rem;
    border: 0.1rem solid #b58762;
    border-radius: 100%;
    cursor: pointer;

    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-of-type(1) {
      transform: scaleX(-1);
    }
  }
`

const SpecsOption = styled.p`
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
`
const CopyWrapper = styled.div`
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`
