import { easings } from "@react-spring/web"

export const anm = {
    from:{
        opacity: 0,
        left: "-150%",
    },
    enter: {
        opacity: 1,
        left: "0%",
        delay: 400
    },
    leave: {
        opacity: 0,
        left: "150%",
    },
    config: {
      duration: 600,
      easing: easings.easeInCubic
    }
}

export const parentCardANMSTYLE = {
    from: {
      opacity: 0,
      transform: "translateY(100%)",
      filter: "blur(20px)",
    },
    enter: {
      opacity: 1,
      transform: "translateY(0%)",
      filter: "blur(0px)",
      delay: 300,
    },
    leave: {
      opacity: 0,
      transform: "translateY(100%)",
      filter: "blur(20px)",
    },
  }
  
  export const cardANMSTYLE = {
    from: {
      opacity: 1,
      left: "-50%",
      delay: 500,
    },
    to: {
      opacity: 1,
      left: "50%",
      delay: 500,
    },
  }