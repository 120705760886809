import React from "react"
import * as THREE from "three"
import { useLoader } from "@react-three/fiber"

import HotSpot from "./HotSpot"

function Sphere({ texture, hotSpots }) {
  const url = `${process.env.PUBLIC_URL}/assets/images/views/${texture}.webp`

  const mainTexture = useLoader(THREE.TextureLoader, url)
  mainTexture.minFilter = THREE.LinearFilter
  mainTexture.wrapS = THREE.RepeatWrapping
  mainTexture.repeat.x = -1

  return (
    <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]} castShadow>
      <sphereGeometry args={[500, 60, 40]} />
      <meshBasicMaterial toneMapped={false} map={mainTexture} side={THREE.BackSide} />
      {hotSpots?.map((theta, i) => (
        <HotSpot key={i} theta={theta} />
      ))}
    </mesh>
  )
}

export default Sphere

export function TourSphere({ texture, hotSpots }) {
  const mainTexture = useLoader(THREE.TextureLoader, texture)
  mainTexture.minFilter = THREE.LinearFilter
  mainTexture.wrapS = THREE.RepeatWrapping
  mainTexture.repeat.x = -1

  return (
    <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]} castShadow>
      <sphereGeometry args={[500, 60, 40]} />
      <meshBasicMaterial toneMapped={false} map={mainTexture} side={THREE.BackSide} />
      {hotSpots?.map((theta, i) => (
        <HotSpot key={i} theta={theta} />
      ))}
    </mesh>
  )
}
