const ascentaUnits = [
  "2001",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2101",
  "2103",
  "2104",
  "2105",
  "2106",
  "2107",
  "2201",
  "2203",
  "2205"
]

export const ascentaFinishes = {
  "2003": "Luminous",
  "2001": "Allure",
  "2004": "Allure",
  "2005": "Luminous",
  "2006": "Allure",
  "2007": "Luminous",
  "2101": "Luminous",
  "2103": "Allure",
  "2104": "Luminous",
  "2105": "Allure",
  "2106": "Luminous",
  "2107": "Allure",
  "2201": "Allure",
  "2203": "Luminous",
  "2205": "Allure"
}

export default ascentaUnits
