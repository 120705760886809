import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring } from "react-spring"

import FloorplanNew from "./components/Floorplan"
import SpecificationsNew from "./components/Specifications"
import CloseIcon from "assets/images/icons/close.svg"
import Views from "./components/Views"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import { availability } from "utils/convertDataFormat"
import FloorplanAscent from "./components/FloorplanAscent"
import ViewsAscent from "./components/ViewsAscent"
import SpecificationsAscent from "./components/SpecificationsAscent"

function ShareCardAscenta({ apartment, isOpen, action, direction, setSwiperActive }) {
  const favorites = useStore(s => s.favorites)
  const isFav = favorites.includes(apartment)
  const [section, setSection] = useState("FLOORPLAN")

  const parentAnm = useTransition(
    isOpen,
    direction
      ? {
          from: {
            opacity: 0,
            left: "0%",
            filter: "blur(5px)",
          },
          enter: {
            opacity: 1,
            left: "50%",
            filter: "blur(0px)",
            delay: 300,
          },
          leave: {
            opacity: 0,
            left: "100%",
            filter: "blur(5px)",
          },
        }
      : {
          from: {
            opacity: 0,
            left: "100%",
            filter: "blur(5px)",
          },
          enter: {
            opacity: 1,
            left: "50%",
            filter: "blur(0px)",
            delay: 300,
          },
          leave: {
            opacity: 0,
            left: "0%",
            filter: "blur(5px)",
          },
        },
  )

  const cardAnm = useSpring({
    from: {
      opacity: 1,
      top: "0%",
    },
    to: {
      opacity: 1,
      top: "50%",
    },
  })

  function changeSection(sec) {
    setSection(sec)
  }

  useEffect(() => {
    setSwiperActive(section !== "VIEWS")
  }, [section])
  return parentAnm(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm}>
          <Card style={cardAnm}>
            <Display>
              <FloorplanAscent section={section} action={changeSection} apartment={apartment} />
              <ViewsAscent section={section} action={changeSection} apartment={apartment} />
              <SpecificationsAscent
                section={section}
                action={changeSection}
                apartment={apartment}
              />
            </Display>
          </Card>
        </Wrapper>
      ),
  )
}

export default ShareCardAscenta

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  .close {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 10%;
    aspect-ratio: 1/1;
    pointer-events: all;
    filter: drop-shadow(-3px -3px 10px #b58762);
  }
  .ascent-plate {
    mask-image: radial-gradient(rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0));
    & g {
      &:nth-of-type(1) {
        & path {
          opacity: 0.3;
        }
        & g path {
          opacity: 1;
          fill: black;
        }
      }
      &:nth-of-type(2) {
        & g polygon {
          fill: white !important;
        }
        & g text {
          fill: black !important;
        }
      }
    }

    #active-poly {
      fill-opacity: 0.2;
      fill: #b58762 !important;
    }
  }
`

const Card = styled(a.div)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: white;
`

const Display = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
