import React, { useState, useRef } from "react"
import styled from "styled-components"
import { a, useSpring } from "react-spring"
import { useDrag } from "react-use-gesture"

import FloorplanNew from "./components/Floorplan"
import SpecificationsNew from "./components/Specifications"
import Views from "./components/Views"
import Fav from "assets/images/icons/fav.png"
import FavActive from "assets/images/icons/fav-active.png"
import CloseIcon from "assets/images/icons/close.svg"

import { useStore } from "state/store"
import { APART_PROPS } from "dataset/finder"
import { availability } from "utils/convertDataFormat"

function FavoriteCard({ activeFavourite, setActiveFavourite, apartment, style, index, openCards }) {
  const favorites = useStore(s => s.favorites)
  const toggleFavorites = useStore(s => s.toggleFavourites)
  const isFav = favorites.includes(apartment)
  const [section, setSection] = useState("FLOORPLAN")

  function changeSection(sec) {
    setSection(sec)
  }
  const cardRef = useRef()
  const isOpen = useRef(false)

  const [apiStyle, api] = useSpring(() => ({
    from: {
      top: `${index * 30}%`,
      left: "100%",
    },
    to: {
      top: `${index * 30}%`,
      left: "80%",
    },
    delay: index * 300,
  }))

  const drag = useDrag(
    e => {
      if (isOpen.current) {
        e.movement[0] > 100 && handleClosing()
      } else {
        e.movement[0] < -100 && handleOpening()
      }
    },
    { threshold: 100, enabled: section !== "VIEWS" },
  )

  const delta = useRef({
    down: {},
    up: {},
  })

  function pointerDown(e) {
    delta.current.down = { clientXDown: e.clientX, clientYDown: e.clientY, time: e.timeStamp }
  }
  function pointerUp(e, action) {
    delta.current.up = { clientXDown: e.clientX, clientYDown: e.clientY, time: e.timeStamp }
    handleAnimationClick(action)
  }

  function handleAnimationClick(action) {
    switch (action) {
      case "open":
        handleOpening()
        break
      case "close":
        handleClosing()
        break
      default:
        return
    }
  }

  function handleClosing() {
    openCards.current.pop()
    const last = openCards.current.length - 1
    setActiveFavourite(null)
    api.start({
      from: {
        top: `${index * 30}%`,
        left: "-20%",
      },
      to: {
        top: `${index * 30}%`,
        left: "80%",
      },
    })
    if (openCards.current.length) {
      cardRef.current.parentNode.scrollTo({
        top: openCards.current[last]?.offsetTop,
        behavior: "smooth",
      })
    } else {
      cardRef.current.parentNode.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
    isOpen.current = false
    setSection("FLOORPLAN")
  }

  function handleOpening() {
    openCards.current.push(cardRef.current)
    setActiveFavourite(apartment)
    api.start({
      from: {
        top: `${index * 30}%`,
        left: "80%",
      },
      to: {
        top: `${index * 30}%`,
        left: "-20%",
      },
    })
    cardRef.current.parentNode.scrollTo({
      top: cardRef.current.offsetTop,
      behavior: "smooth",
    })
    isOpen.current = true
  }
  return (
    <Wrapper ref={cardRef} style={{ ...style, ...apiStyle }} id={`favCard${index + 1}`}>
      <Index
        onPointerDown={e => {
          pointerDown(e)
        }}
        onPointerUp={e => {
          pointerUp(e, "open")
        }}>
        <p>
          <span>&#9826; </span>0{index + 1}
          <span> &#9826;</span>
        </p>
        <h1>{apartment?.[APART_PROPS.unit]}</h1>
        <h3>{apartment?.[APART_PROPS.unitType]}</h3>
        <h3>
          {apartment?.[APART_PROPS.beds] === "1"
            ? `${apartment?.[APART_PROPS.beds]} BED`
            : `${apartment?.[APART_PROPS.beds]} BEDS`}
        </h3>
      </Index>
      <Card>
        <Display>
          <FloorplanNew
            isFav={true}
            section={section}
            action={changeSection}
            apartment={apartment}
          />
          <Views isFav section={section} action={changeSection} apartment={apartment} />
          <SpecificationsNew section={section} action={changeSection} />
        </Display>
        <span>
          <Header>
            <h1>
              <span>&#9826; </span>
              {apartment?.[APART_PROPS.unit]}
              <span> &#9826;</span>
            </h1>
            <h3>{apartment?.[APART_PROPS.unitType]}</h3>
            <h3 style={{ letterSpacing: "0.3rem" }}>
              {apartment && availability(apartment["Availability"], apartment[APART_PROPS.price])}
            </h3>
            <h3>
              {apartment?.[APART_PROPS.beds] === "1"
                ? `${apartment?.[APART_PROPS.beds]} BED`
                : `${apartment?.[APART_PROPS.beds]} BEDS`}
            </h3>
            <FavButton
              onClick={() => {
                toggleFavorites(apartment)
              }}>
              <img src={isFav ? FavActive : Fav} alt="" />
            </FavButton>
          </Header>
        </span>
      </Card>
      <Close
        onPointerDown={e => {
          pointerDown(e)
        }}
        onPointerUp={e => {
          pointerUp(e, "close")
        }}>
        <img src={CloseIcon} alt="" />
      </Close>
    </Wrapper>
  )
}

export default FavoriteCard

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: black;
  border: 1px solid #b58762;
  touch-action: none;

  & span {
    filter: drop-shadow(5px 5px 15px black);
    pointer-events: none;
  }

  > * {
    touch-action: pan-y !important;
  }
`

const Card = styled.div`
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  height: 80%;
`

const Header = styled.div`
  position: relative;
  left: 0;
  height: 100%;
  width: 25%;
  padding-bottom: 2%;
  clip-path: polygon(0% 0%, 15% 0%, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: black;
  color: #b58762;
  gap: 0.5em;

  & h1 {
    font-size: 3em;
    font-weight: 100;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    & span {
      font-size: 0.2em;
      font-weight: 100;
    }
  }

  & h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;

    :nth-of-type(1) {
      color: white;
      font-size: 1em;
    }
  }
`

const Display = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const FavButton = styled.div`
  pointer-events: auto;
  cursor: pointer;
  position: relative;
  display: grid;
  place-content: center;

  & img {
    width: 100%;
    height: 100%;
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin-top: 0.5rem;
  }
`

const Index = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  padding: 2%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #b58762;
  cursor: pointer;

  & h1 {
    font-size: 4em;
    font-weight: 100;
    white-space: nowrap;
    text-align: right;
    margin: 0;

    & span {
      font-size: 0.2em;
      font-weight: 100;
    }
  }

  & h3 {
    text-align: right;
    text-transform: uppercase;
    font-size: 1em;

    :nth-of-type(1) {
      color: white;
      font-size: 1.2em;
    }
  }

  & p {
    position: absolute;
    top: 0;
    left: 0;
    margin: 5%;
    font-size: 1.5rem;
    font-family: Bliss-Light;
    & span {
      font-size: 0.8rem;
    }
  }
`

const Close = styled.div`
  position: absolute;
  right: 1%;
  width: 5%;
  aspect-ratio: 1/1;
  cursor: pointer;
  z-index: 5;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border: 1px solid #b58762;
    border-radius: 100%;
    padding: 5%;
  }
`
