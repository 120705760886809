import styled from "styled-components/macro"
import { AnimatePresence, motion } from "framer-motion"
import { Slider } from "primereact/slider"

import IconButton from "components/Buttons/IconButton"
import { priceFormatter } from "utils/convertDataFormat"
import { BEDS, DIRECTIONS } from "dataset/finder"
import { useStore } from "state/store"

import FilterIcon from "assets/images/icons/icon-filter.svg"
import CloseIcon from "assets/images/icons/close-black.svg"
import { useLayoutEffect, useState } from "react"

const types = [
  "The Egerton",
  "The Tavistock",
  "The Sloane",
  "The Finsbury",
  "The Arundel",
  "The Arundel II",
  "The Kensington",
  "The Grosvenor",
  "The Harrington",
  "The Wilton",
  "The Belgrave",
  "The Bloomsbury",
  "The Chester",
]

export default function ApartmentFilter() {
  const resetFilter = useStore(state => state.resetFilter)
  const priceRange = useStore(state => state.priceRange)
  const setPriceRange = useStore(state => state.setPriceRange)
  const [localPriceRange, setLocalPriceRange] = useState([])

  const levelRange = useStore(state => state.levelRange)
  const setLevelRange = useStore(state => state.setLevelRange)
  const [LocalLevelRange, setLocalLevelRange] = useState([])

  const type = useStore(state => state.type)
  const toggleType = useStore(state => state.toggleType)

  const selectedAspects = useStore(state => state.selectedAspects)
  const toggleAspects = useStore(state => state.toggleAspects)

  const bedroomCounts = useStore(state => state.bedroomCounts)
  const toggleBedroomCounts = useStore(state => state.toggleBedroomCounts)

  const minPrice = useStore(state => state.minPrice)
  const maxPrice = useStore(state => state.maxPrice)

  const filterVisible = useStore(state => state.filterVisible)
  const setFilterVisible = useStore(state => state.setFilterVisible)

  const setActiveApartment = useStore(state => state.setActiveApartment)

  const handleAspectChange = e => {
    toggleAspects(e)
  }
  const handleTypeChange = e => {
    toggleType(e.target.id)
  }
  const handlePriceRangeChange = (e, v = false) => {
    setLocalPriceRange(e.value)
    if (v) {
      setPriceRange(e.value)
    }
  }

  const handleLevelRangeChange = (e, v = false) => {
    setLocalLevelRange(e.value)
    if (v) {
      setLevelRange(e.value)
    }
  }

  useLayoutEffect(() => {
    setLocalPriceRange(priceRange)
    setLocalLevelRange(levelRange)
  }, [priceRange, levelRange])

  const handleVisiblity = () => {
    setActiveApartment(null)
    setFilterVisible(!filterVisible)
  }

  return (
    <>
      <FilterHolder visible={filterVisible}>
        <FilterButton
          icon={filterVisible ? CloseIcon : FilterIcon}
          color={filterVisible ? "black" : "white"}
          onClick={handleVisiblity}
        />
      </FilterHolder>
      <AnimatePresence>
        {filterVisible && (
          <Drawer
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: {
                y: "-100%",
                opacity: 0,
              },
              animate: {
                y: 0,
                opacity: 1,
              },
              exit: {
                y: "-100%",
                opacity: 0,
              },
            }}
            transition={{ duration: 0.8 }}>
            <Content>
              <ItemList>
                <ItemHolder>
                  <ItemTitle>Beds</ItemTitle>
                  <List>
                    {BEDS.map((bed, index) => (
                      <ListItem
                        key={index}
                        onClick={() => {
                          toggleBedroomCounts(bed.toString())
                        }}
                        active={bedroomCounts.includes(bed.toString())}>
                        {bed}
                      </ListItem>
                    ))}
                  </List>
                </ItemHolder>
                <ItemHolder>
                  <ItemTitle>Price</ItemTitle>
                  <PriceSlider>
                    <Slider
                      value={localPriceRange}
                      onChange={e => handlePriceRangeChange(e)}
                      onSlideEnd={e => handlePriceRangeChange(e, true)}
                      range
                      step={10000}
                      min={minPrice}
                      max={maxPrice}
                      style={{ width: "100%" }}
                    />
                    <Values>
                      <p>{priceFormatter.format(localPriceRange[0] + 10)}</p>
                      <p>{priceFormatter.format(localPriceRange[1] - 10)}</p>
                    </Values>
                  </PriceSlider>
                </ItemHolder>
                <ItemHolder>
                  <ItemTitle>Facing</ItemTitle>
                  <List>
                    {DIRECTIONS.map((dir, index) => (
                      <ListItem
                        key={index}
                        onClick={e => {
                          handleAspectChange(dir)
                        }}
                        active={selectedAspects.includes(dir)}>
                        {dir}
                      </ListItem>
                    ))}
                  </List>
                </ItemHolder>
                <ItemHolder>
                  <ItemTitle>Level</ItemTitle>
                  <PriceSlider>
                    <Slider
                      value={LocalLevelRange}
                      onChange={e => handleLevelRangeChange(e)}
                      onSlideEnd={e => handleLevelRangeChange(e, true)}
                      range
                      step={1}
                      min={1}
                      max={22}
                      style={{ width: "100%" }}
                    />
                    <Values>
                      <p>{LocalLevelRange[0]}</p>
                      <p>{LocalLevelRange[1]}</p>
                    </Values>
                  </PriceSlider>
                </ItemHolder>
                <ItemHolder>
                  <ItemTitle>RESIDENCE TYPE</ItemTitle>
                  <List>
                    <TypeHolder>
                      {types.map((t, i) => (
                        <Type
                          key={i}
                          active={type.includes(t)}
                          id={t}
                          onClick={e => handleTypeChange(e)}>
                          {t.toUpperCase()}
                        </Type>
                      ))}
                    </TypeHolder>
                  </List>
                </ItemHolder>
                <ItemHolder>
                  <div
                    style={{
                      right: 0,
                      width: "100%",
                      height: "2em",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}>
                    <div
                      style={{
                        cursor: "pointer",
                        color: "white",
                        textAlign: "right",
                        width: "8em",
                      }}
                      onClick={() => resetFilter()}>
                      REFRESH
                    </div>
                  </div>
                </ItemHolder>
              </ItemList>
            </Content>
          </Drawer>
        )}
      </AnimatePresence>
    </>
  )
}

const TypeHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  max-height: 230px;
  /* overflow-y: scroll; */
  gap: 7%;
  font-size: 0.8em;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b48465;
    /* border-radius: 10px; */
  }
`

const Type = styled.div`
  color: ${props => (props.active ? "#b48465" : "#ffffffd0;")};
  font-family: Bliss-Light;
  font-weight: 100;
  cursor: pointer;
  margin-top: 0.7em;
  flex-grow: 1;
  width: 43%;
  display: flex;
  justify-content: flex-start;

  transition: all 0.3s ease;
`

const FilterHolder = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.visible ? "#b48465" : "#212121")};
  padding: 10em 2em 2em 6em;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0% 100%);
  transition: all 0.3s ease-out;
`

const FilterButton = styled(IconButton)``

const Drawer = styled(motion.div)`
  position: fixed;
  top: 4em;
  right: 0;
  z-index: 2;
  width: 35%;
  max-width: 600px;
  height: 60%;
  
  @media (min-width: 3000px) {
    top: 0em;
    min-height: 1400px;
    max-width: 1000px;
  }
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3em 4em 3em 2em;
  font-size: 0.8em;
  
  @media (min-width: 3000px) {
    padding: 3em 4em 0em 2em;
  }
`

const ItemList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
`

const ItemHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1em;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff62;
`

const ItemTitle = styled.div`
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const PriceSlider = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

const Values = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5em;
  letter-spacing: 0.15em;
  p {
    color: #e5e1e1;
    width: 6.5em;
  }
  > * {
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
  margin-right: 1rem;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5em;
  height: 3.5em;
  color: ${props => (props.active ? "#b48465" : "white")};
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease-out;
`
