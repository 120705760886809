import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { a, useTransition, useSpring } from "react-spring"

import FloorplanMobile from "./FloorplanMobile"
import CloseIcon from "assets/images/icons/close.svg"

import { APART_PROPS } from "dataset/finder"
import Views from "./ViewsMobile"
import SpecsMobileAscenta from "./SpecsMobileAscenta"
import { BEDLABELS } from "components/ApartmentCard/components/FloorplanAscent"

function ShareCardMobileAscenta({ apartment, isOpen, action, direction, setSwiperActive }) {
  const [section, setSection] = useState("FLOORPLAN")
  const sections = ["FLOORPLAN", "VIEWS", "SPECS"]
  const parentAnm = useTransition(
    isOpen,
    direction
      ? {
          from: {
            opacity: 0,
            left: "0%",
          },
          enter: {
            opacity: 1,
            left: "50%",
            delay: 300,
          },
          leave: {
            opacity: 0,
            left: "100%",
          },
        }
      : {
          from: {
            opacity: 0,
            left: "100%",
          },
          enter: {
            opacity: 1,
            left: "50%",
            delay: 300,
          },
          leave: {
            opacity: 0,
            left: "0%",
          },
        },
  )

  const cardAnm = useSpring({
    from: {
      opacity: 1,
      top: "0%",
    },
    to: {
      opacity: 1,
      top: "50%",
    },
  })

  function handleClosing() {
    action(false)
    // return setTimeout(() => {
    //   setapartment(null)
    //   setFullCardOpen(true)
    //   setSection("FLOORPLAN")
    // }, 800)
  }

  function changeSection(sec) {
    setSection(sec)
  }
  useEffect(() => {
    setSwiperActive(section !== "VIEWS")
  }, [section])
  return parentAnm(
    (anm, loaded) =>
      loaded && (
        <Wrapper style={anm}>
          <Card style={cardAnm}>
            <Header>
              <h3>ASCENTA</h3>
              <h1>
                N° <span>{apartment[APART_PROPS.unit]}</span>
              </h1>
              <h4>{BEDLABELS[apartment[APART_PROPS.beds]]}</h4>
            </Header>
            {/* <span className="close">
              <Close
                onClick={() => {
                  handleClosing()
                }}>
                <img src={CloseIcon} alt="" />
              </Close>
            </span> */}
            <Sections>
              {sections.map((sect, i) => {
                return (
                  <Section
                    key={`sect${i}`}
                    selected={section === sect}
                    onClick={() => {
                      changeSection(sect)
                    }}>
                    {sect}
                  </Section>
                )
              })}
            </Sections>
            <Display>
              <FloorplanMobile section={section} apartment={apartment} isAscenta />
              <Views section={section} apartment={apartment} isAscenta />
              <SpecsMobileAscenta section={section} apartment={apartment} />
            </Display>
          </Card>
        </Wrapper>
      ),
  )
}

export default ShareCardMobileAscenta

const Wrapper = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  & span {
    filter: drop-shadow(5px 5px 15px black);
    pointer-events: none;
  }

  .close {
    position: fixed;
    top: 0;
    right: 0;
    width: 20%;
    aspect-ratio: 1/1;
    pointer-events: all;
    z-index: 5;
  }
`

const Card = styled(a.div)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  position: relative;
  left: 0;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: #b58762;
  gap: 0.1em;
  font-size: 1rem;

  & span {
    filter: none;
  }

  & h4 {
    font-size: 0.7em;
    letter-spacing: 0.2em;
    text-align: center;
    font-weight: 100;
    color: black;
    font-family: Bliss-Light;
  }
  & h3 {
    font-size: 0.8em;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: 600;
    color: #b58762;
  }
  & h1 {
    font-size: 1.8em;
    letter-spacing: 0.1em;
    line-height: 0;
    text-align: center;
    font-weight: 600;
    color: #b58762;
    & span {
      font-family: Classico;
    }
  }
`

const Display = styled.div`
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 100%;
  height: 74%;
  max-height: 74%;
  transform: translateX(-50%);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Close = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  background-color: white;

  & img {
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 5;
    width: 35%;
    aspect-ratio: 1/1;
    border: 1px solid #b58762;
    border-radius: 100%;
    padding: 5%;
  }
`

const Sections = styled.div`
  width: 100%;
  height: 6%;
  background-color: white;
  pointer-events: all;
  touch-action: all;
`
const Section = styled.div`
  display: inline-grid;
  place-content: center;
  width: calc(100% / 3);
  height: 100%;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  background-color: ${p => (p.selected ? "#b58762" : "transparent")};
  color: ${p => (p.selected ? "white" : "black")};
  transition: all 0.4s ease-in-out;
`
